export function formatarDataHora(data) {
  try {
    const dataObj = new Date(data);
    return (
      Intl.DateTimeFormat("pt-BR").format(dataObj) +
      " às " +
      Intl.DateTimeFormat("pt-BR", {
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      }).format(dataObj)
    );
  } catch (e) {
    return "Data inválida.";
  }
}

export function formatarData(data) {
  try {
    const dataObj = new Date(data);
    return Intl.DateTimeFormat("pt-BR").format(dataObj);
  } catch (e) {
    return "Data inválida.";
  }
}

export function formatarHora(data) {
  try {
    const dataObj = new Date(data);
    return Intl.DateTimeFormat("pt-BR", {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    }).format(dataObj);
  } catch (e) {
    return "Hora inválida.";
  }
}
