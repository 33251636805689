import React, { useEffect, useState } from 'react'
import useRequest from '../../hooks/useRequest';
import { showToast } from '../../utility/Utils';
import { isLoggedIn } from '../../models/general_model';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { AlertTriangle } from 'react-feather';
import { Media } from 'reactstrap';
import { formatarDataHora } from '../../utility/dateFormatter';

export const NotificationContext = React.createContext();

const NotificationContextProvider = ({ children }) => {
    const history = useHistory();
    const [loggedIn, setIsLoggedIn] = useState(isLoggedIn());
    const [notifications, setNotifications] = useState([]);
    const [shownNotifications, setShownNotifications] = useState([]);
    const notificationsPerLoad = 5;
    const [maxNotifications, setMaxNotifications] = useState(notificationsPerLoad);
    const [menuOpen, setMenuOpen] = useState(false);
    const fetcher = useRequest();

    const getNewNotifications = async () => {
        const data = await fetcher.get('notificacoes');
        setNotifications(data.notifications);
        if (!menuOpen || shownNotifications.length === 0) {
            setShownNotifications(
                loadNotifications(
                    0,
                    maxNotifications,
                    data.notifications
                )
            );
        }
    }

    const hasUpdate = () => {
        if (notifications.length === shownNotifications.length) return false;
        if (notifications.length <= 0 || shownNotifications.length <= 0) return false;
        return shownNotifications[0].id !== notifications[0].id;
    }

    const refreshNotifications = () => {
        setShownNotifications(
            loadNotifications(0,
                maxNotifications,
                JSON.parse(JSON.stringify(notifications))
            )
        );
    }

    const readNotification = async (e, notificacao) => {
        e.preventDefault();

        const response = await fetcher.del(
            `notificacoes/${notificacao.id}`,
        );

        const notificationLink = (notificacao.link[0] !== "/") ? "/" + notificacao.link : notificacao.link;
        if (typeof response.message === "undefined") {
            history.push(notificationLink);
            return;
        }

        setShownNotifications([]);
        getNewNotifications();
        history.push(notificationLink);
    }

    const readAllNotifications = async () => {
        const response = await fetcher.del(
            `notificacoes`,
        );

        if (typeof response.message === "undefined") return;

        setNotifications([]);
        setShownNotifications([]);

        showToast("success", "Sucesso!", "Notificações Limpas!");
    };

    const removeNotificationFromLists = (notificacao) => {
        const filteredNotifications = notifications.filter((not) => not.id !== notificacao.id);
        let actualNotif = [filteredNotifications];
        setNotifications(actualNotif);

        setShownNotifications(
            loadNotifications(
                0,
                maxNotifications,
                actualNotif
            )
        );
    }

    const loadMoreNotifications = () => {
        let notificationsNewArray = loadNotifications(
            maxNotifications,
            maxNotifications + notificationsPerLoad
        );
        setMaxNotifications(maxNotifications + notificationsPerLoad);

        setShownNotifications(notificationsNewArray);
    };

    const loadNotifications = (initialNumber = 0, finalNumber = notificationsPerLoad, notificationsArr = null) => {
        let notificationsNewArray = initialNumber === 0 ? [] : [...shownNotifications];

        const maxNotificationsNow = finalNumber;
        notificationsArr = notificationsArr === null ? notifications : notificationsArr;

        for (let i = initialNumber; i < maxNotificationsNow; i++) {
            if (i > notificationsArr.length || typeof notificationsArr[i] === "undefined") break;

            notificationsNewArray.push({
                id: notificationsArr[i].id,
                link: notificationsArr[i].link,
                avatarIcon: <AlertTriangle size={14} />,
                subtitle: notificationsArr[i].texto,
                data: formatarDataHora(notificationsArr[i].created_at),
                title: (
                    <Media tag="p" heading>
                        <span className="font-weight-bolder">{notificationsArr[i].titulo}</span>
                    </Media>
                ),
            });
        }

        return notificationsNewArray;
    }


    useEffect(() => {
        if (loggedIn) {
            getNewNotifications();
            window.notificationInterval = setInterval(getNewNotifications, 10000);
        }

        return () => {
            if (typeof window.notificationInterval !== 'undefined') {
                clearInterval(window.notificationInterval);
            }
        }
    }, [loggedIn]);

    return (
        <NotificationContext.Provider
            value={{
                shownNotifications,
                menuOpen,
                loggedIn,
                setIsLoggedIn,
                hasUpdate,
                setMenuOpen,
                notifications,
                refreshNotifications,
                readNotification,
                readAllNotifications,
                removeNotificationFromLists,
                loadMoreNotifications,
                loadNotifications,
            }}
        >
            {children}
        </NotificationContext.Provider>
    )
}

export default NotificationContextProvider