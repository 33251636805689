import axios from "axios";

const urls = {
  login: "/login",
  perfil: "/perfil",
  notificacoes: "/notificacoes",
};

export const LOGIN_URL = window.location.href.includes("atendimento.") ? "https://app.syllosdoc.com" : "http://localhost:3000";

const clearSession = () => {
  if (localStorage.getItem("portalsyllos_user") !== null)
    localStorage.removeItem("portalsyllos_user");
  if (localStorage.getItem("portalsyllos_loggedIn") !== null)
    localStorage.removeItem("portalsyllos_loggedIn");
  if (localStorage.getItem("@portalsyllos") !== null)
    localStorage.removeItem("@portalsyllos");
};

export const saveDadosUser = async (dados) => {
  clearSession();
  const jToken = dados.access_token;
  delete dados.access_token;
  localStorage.setItem("portalsyllos_user", JSON.stringify(dados));
  localStorage.setItem("@portalsyllos", jToken);
  localStorage.setItem("portalsyllos_loggedIn", true);
};

export const saveDadosSession = async (dados) => {
  localStorage.setItem("portalsyllos_user", JSON.stringify(dados));
};

export const logoff = () => {
  clearSession();
  localStorage.removeItem("syllos@accept");
  return true;
};

export const logoffRedirecting = (timeout = 3000) => {
  let redirectUri = LOGIN_URL + "/api/auth/signout/";

  clearSession();
  localStorage.removeItem("syllos@accept");

  if (timeout > 0) {
    setTimeout(() => {
      window.location.href = redirectUri;
    }, timeout);
  } else {
    window.location.href = redirectUri;
  }

  return true;
}

export const login = async (login, password) => {
  const request = {
    usuario: login,
    senha: password,
  };

  const dadosLogin = await axios.post(urls.login, request);
  if (typeof dadosLogin === "undefined") return null;

  if (typeof dadosLogin.data.error !== "undefined") return dadosLogin.data;

  saveDadosUser(dadosLogin.data);
  return dadosLogin.data;
};

export const isLoggedIn = () => {
  return localStorage.getItem("portalsyllos_loggedIn") !== null
    ? localStorage.getItem("portalsyllos_loggedIn")
    : false;
};

export const getUserType = () => {
  if (!isLoggedIn()) return null;

  const data = JSON.parse(localStorage.getItem("portalsyllos_user"));
  return data.user_type;
};

export const getUserData = () => {
  if (!isLoggedIn()) return null;

  const data = JSON.parse(localStorage.getItem("portalsyllos_user"));
  return data;
};

const ability_usuario = () => {
  const userData = getUserData();

  return userData.permissoes;
};

const ability_admin_empresa = () => {
  const userData = getUserData();

  return userData.permissoes;
};

const ability_empresa = () => {
  const userData = getUserData();

  return userData.permissoes;
};

export const ability_super = () => {
  return [
    {
      action: "manage",
      subject: "all",
    },
  ];
};

const ability_admin = () => {
  const userData = getUserData();
  if (userData.super === 1) return ability_super();

  return userData.permissoes;
};

export const getNotifications = async () => {
  const type = getUserType();

  const notificacoes = await axios.get(type + urls.notificacoes);
  if (typeof notificacoes === "undefined" || notificacoes.data) return [];

  return notificacoes.data;
};

export const getUserAbility = () => {
  const userType = getUserType();

  if (!userType) return null;

  switch (userType) {
    case "admin":
      return ability_admin();
    case "admin-empresa":
      return ability_admin_empresa();
    case "empresa":
      return ability_empresa();
    default:
      return ability_usuario();
  }
};

export const getApiUrl = (parts) => {
  return "https://api.syllosdoc.com/api/arquivos";
};

export const sendRequest = async (
  url,
  method = "GET",
  data = null,
  headers = null,
  addType = true,
  responseType = null
) => {
  let sendUrl = url[0] === "/" ? url : "/" + url;
  const type = getUserType();
  const config = {
    method: method,
    url: addType ? type + sendUrl : sendUrl,
  };
  if (data !== null) config.data = data;

  if (headers !== null) config.headers = headers;

  if (responseType !== null) config.responseType = responseType;

  const response = await axios(config).catch(function (error) {
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    }
  });

  if (response === null || typeof response === "undefined") {
    return null;
  }

  if (typeof response.data === "undefined") {
    return response;
  }

  return response.data;
};

export const fileData = async (url) => {
  let urlSender = url.replace(axios.defaults.baseURL, "");

  const config = {
    method: "GET",
    url: urlSender,
    responseType: "blob",
  };

  const response = await axios(config).catch(function (error) {
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    }
  });

  if (response === null || typeof response === "undefined") {
    return null;
  }

  if (typeof response.data === "undefined") {
    return response;
  }

  return response.data;
};
