class DateTime {
  constructor(dateTimeString = null, format = "Y-m-d H:i:s") {
    this.originalString =
      dateTimeString === null ? this.startNewDate() : dateTimeString;
    this.originalFormat = format;
    this.shouldFormatTo = "";
    this.initialFormat = [];
    this.data = [];
    this.attributes = {};
    this.endingStr = "";
    this.convertDateTime();
  }

  startNewDate() {
    let currentdate = new Date();
    return (
      currentdate.getFullYear() +
      "-" +
      (currentdate.getMonth() + 1) +
      "-" +
      currentdate.getDate() +
      " " +
      currentdate.getHours() +
      ":" +
      currentdate.getMinutes() +
      ":" +
      currentdate.getSeconds()
    );
  }

  isNumeric(s) {
    return !isNaN(s - parseFloat(s));
  }

  convertDateTime() {
    let format = this.originalFormat.replace(/[^a-zA-Z]/g, "-");
    let str = this.originalString.replace("Z", "").replace(/[^0-9.]/g, "-");
    this.data = str.split("-");
    this.initialFormat = format.split("-");

    this.initialFormat.forEach((format, index) => {
      this.attributes[format] = this.data[index];
    }, this);
  }

  format(formatType = "d/m/Y H:i") {
    this.shouldFormatTo = formatType;
    this.endingStr = "";
    for (let i = 0; i < this.shouldFormatTo.length; i++) {
      let key = this.shouldFormatTo[i];
      if (typeof this.attributes[key] !== "undefined") {
        let integer = parseInt(this.attributes[key]);
        let toWrite = integer <= 9 ? "0" + integer : integer;
        this.endingStr += toWrite;
        continue;
      }

      this.endingStr += key;
    }

    return this.endingStr;
  }
}

export default DateTime;
