import { isObject } from "reactstrap/lib/utils";
import {
  isLoggedIn,
  getUserData as getUserDataModel,
  sendRequest,
} from "../models/general_model";
import DateTime from "./DateTime/DateTime";
import { Slide, toast } from "react-toastify";
import AvisosModal from "../views/custom/modal/AvisosModal";
// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

function checkIfIsDate(date) {
  if (Object.prototype.toString.call(date) !== "[object Date]") return false;

  if (isNaN(date)) return false;

  return true;
}

function convertToDateObj(string, initialFormat) {
  let splitterTime = string.split(" ");
  let separator = initialFormat[1];
  let splitInit = initialFormat.split(" ");
  let date = splitInit[0].split(separator);
  let time = null;
  let timeSeparator = ":";
  if (typeof splitInit[1] !== "undefined") {
    timeSeparator = splitInit[1][1];
    time = splitInit[1].split(timeSeparator);
  }

  let sendDate = splitterTime[0].split(separator);
  if (typeof sendDate[1] === "undefined" || typeof sendDate[2] === "undefined")
    return null;

  let year = null;
  let month = null;
  let day = null;
  let hours = null;
  let minutes = null;
  let seconds = null;
  date.forEach((dt, i) => {
    if (dt === "Y") year = sendDate[i];
    if (dt === "m") month = sendDate[i];
    if (dt === "d") day = sendDate[i];
  });
  if (typeof splitterTime[1] !== "undefined" && time) {
    let sendTime = splitterTime[1].split(timeSeparator);
    time.forEach((dt, i) => {
      if (dt === "H") hours = sendTime[i];
      if (dt === "i") minutes = sendTime[i];
      if (dt === "s") seconds = sendTime[i];
    });
  }

  let intYear = parseInt(year);
  let intMonth = parseInt(month) - 1;
  let intDay = parseInt(day);

  if (typeof splitterTime[1] === "undefined" || !time)
    return new Date(intYear, intMonth, intDay);

  let intHours = hours ? parseInt(hours) : null;
  let intMinutes = minutes ? parseInt(minutes) : null;
  let intSeconds = seconds ? parseInt(seconds) : null;

  if (intHours && intMinutes && intSeconds)
    return new Date(
      intYear,
      intMonth,
      intDay,
      intHours,
      intMinutes,
      intSeconds
    );
  if (intHours && intMinutes)
    return new Date(intYear, intMonth, intDay, intHours, intMinutes);
  if (intHours) return new Date(intYear, intMonth, intDay, intHours);

  return new Date(intYear, intMonth, intDay);
}

export const dateTimeNoValidation = (dateTime) => {
  let splitter = dateTime.split(" ");
  let date = splitter[0].split("-");
  return date[2] + "/" + date[1] + "/" + date[0];
};

export const dateTime = (
  date = "",
  format = "Y-m-d",
  initialFormat = "Y-m-d H:i:s"
) => {
  if (typeof date === "string") {
    if (date === "" || date === "NOW" || date === "now") {
      date = new Date();
    } else {
      date = convertToDateObj(date, initialFormat);
    }
  }
  if (!checkIfIsDate(date)) return null;

  const Y = date.getFullYear();
  let mm = date.getMonth() + 1;
  let dd = date.getDate();
  const d = dd < 10 ? `0${dd}` : dd;
  const m = mm < 10 ? `0${mm}` : mm;

  let retorno = format;
  retorno = retorno.replaceAll("Y", Y);
  retorno = retorno.replaceAll("m", m);
  retorno = retorno.replaceAll("d", d);
  return retorno;
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: "short", day: "numeric" };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" };
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => {
  return isLoggedIn();
};
export const getUserData = () => {
  return getUserDataModel();
};

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return "/";
  if (userRole === "usuario") return "/";
  if (userRole === "empresa") return "/";
  if (userRole === "admin-empresa") return "/";
  return "/login";
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed", // for input hover border-color
  },
});

export const ucfirst = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const ucwords = (string) => {
  let words = string.split(" ");
  return words.reduce((str, word, idx) => {
    if (idx > 0) {
      str += " ";
    }
    str += ucfirst(word);
    return str;
  }, "");
};

export const getTipoProcesso = (tipo_processo) => {
  const tipos = [
    {
      label: "Abertura de conta",
      value: "abertura_conta",
    },
    {
      label: "Conta encerrada",
      value: "conta_encerrada",
    },
    {
      label: "Movimentação de conta",
      value: "movimentacao_conta",
    },
    {
      label: "Contratos",
      value: "contratos",
    },
    {
      label: "Cartão de crédito",
      value: "cartao_credito",
    },
    {
      label: "Cheque especial",
      value: "cheque_especial",
    },
    {
      label: "Movimento de caixa",
      value: "movimento_caixa",
    },
    {
      label: "Administrativo",
      value: "administrativo",
    },
    {
      label: "Financeiro / contabil",
      value: "financeiro",
    },
  ];

  let retorno = "";
  for (var i = 0; i < tipos.length; i++) {
    if (tipos[i].value === tipo_processo) {
      return tipos[i].label;
    }
  }
};

export const getServicoSolicitado = (val) => {
  const tipos = [
    {
      label: "",
      value: "",
    },
    {
      label: "Imagem",
      value: "imagem",
    },
    {
      label: "Malote",
      value: "malote",
    },
    {
      label: "Sedex",
      value: "sedex",
    },
    {
      label: "Transferência entre agências",
      value: "transferencia",
    },
  ];

  let retorno = "";
  for (var i = 0; i < tipos.length; i++) {
    if (tipos[i].value === val) {
      return tipos[i].label;
    }
  }
};

export const convertGetValue = (paramStr, key, val) => {
  if (val.constructor === Array) {
    val.forEach((v) => {
      paramStr = paramStr === "" ? "?" : paramStr + "&";
      paramStr += key + "[]=" + encodeURIComponent(v);
    });

    return paramStr;
  }

  if (val.constructor === Object) {
    const keys = Object.keys(val);

    keys.forEach((k) => {
      paramStr += convertGetValue(paramStr, k, val[k]);
    });

    return paramStr;
  }

  paramStr = paramStr === "" ? "?" : paramStr + "&";
  paramStr += key + "=" + encodeURIComponent(val);

  return paramStr;
};

export const formUrl = (uri, params = null) => {
  uri = uri[0] === "/" ? uri : "/" + uri;
  const sendurl = base_url + uri;

  let paramStr = "";

  if (!isObject(params)) return sendurl;

  const keys = Object.keys(params);

  keys.forEach((key) => {
    paramStr = convertGetValue(paramStr, key, params[key]);
  });

  return sendurl + paramStr;
};

export const formUrlParams = (uri, params = null) => {
  const sendurl = uri;

  let paramStr = "";

  if (!isObject(params)) return sendurl;

  const keys = Object.keys(params);

  keys.forEach((key) => {
    paramStr = paramStr === "" ? "?" : paramStr + "&";
    paramStr += key + "=" + encodeURIComponent(params[key]);
  });

  return sendurl + paramStr;
};

export const endpointWithParams = (endpoint, params = null) => {
  const sendurl = endpoint;

  let paramStr = "";

  if (!isObject(params)) return sendurl;

  const keys = Object.keys(params);

  keys.forEach((key) => {
    paramStr = paramStr === "" ? "?" : paramStr + "&";
    paramStr += key + "=" + encodeURIComponent(params[key]);
  });

  return sendurl + paramStr;
};

export const setValidatedValue = (
  event,
  data,
  errors,
  setData,
  setErrors,
  validations
) => {
  let values = { ...data };
  let errorValues = { ...errors };
  values[event.target.name] = event.target.value;
  errorValues[event.target.name] =
    typeof validations[event.target.name] === "function" &&
      !validations[event.target.name](event.target.value)
      ? true
      : false;

  setData(values);
  setErrors(errorValues);
};

export const validateData = (data, errors, setErrors, validations) => {
  let errorValues = { ...errors };

  let keys = Object.keys(data);
  let retorno = true;
  keys.forEach((key) => {
    errorValues[key] =
      typeof validations[key] === "function" && !validations[key](data[key])
        ? true
        : false;
    if (errorValues[key]) retorno = false;
  });
  setErrors(errorValues);

  return retorno;
};

export const getSelectValue = (name, data, options) => {
  if (options === null) return null;

  return options.filter((opt) => {
    return opt.value === data[name];
  })[0];
};

export const setSelectValue = (
  key,
  value,
  data,
  errors,
  setData,
  setErrors,
  isRequired = true
) => {
  let oldInput = { ...data };
  let oldErrors = { ...errors };
  oldInput[key] = value;
  if (value === "" && isRequired) {
    oldErrors[key] = true;
    setData(oldInput);
    setErrors(oldErrors);
    return false;
  }

  oldErrors[key] = false;

  setData(oldInput);
  setErrors(oldErrors);
};

export const renderBreaks = (text) => {
  if (typeof text === "undefined") return "";
  return text.split("\n").map((sub, i) => (
    <span key={`part${i}`}>
      {i > 0 && <br />}
      {sub}
    </span>
  ));
};

export const strGenerator = (size = 10, withUppercase = true) => {
  const letters = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "w",
    "y",
    "v",
    "x",
    "z",
  ];
  var searchableLetters = [];

  letters.forEach((letter) => {
    searchableLetters.push(letter);
    if (withUppercase) searchableLetters.push(letter.toUpperCase());
  });
  var str = "";
  for (let i = 0; i < size; i++) {
    const rand = Math.floor(Math.random() * (searchableLetters.length - 1));
    str += searchableLetters[rand];
  }
  return str;
};

export const getTimestamp = () => {
  const currentDate = new Date();
  return currentDate.getTime();
};

export const idGenerator = () => {
  const salt = Math.floor(Math.random() * 100000);

  return (
    strGenerator(15, true) +
    "-" +
    getTimestamp() +
    "-" +
    salt +
    "-" +
    strGenerator(7, true)
  );
};

export const getObjectNestedValue = (actualValue, nestedKeys) => {
  let nestedArray = nestedKeys.split('.');

  if (typeof actualValue[nestedArray[0]] === 'undefined') return null;

  if (nestedArray.length > 1) {
    let nextValue = { ...actualValue[nestedArray[0]] };
    let remainingNestedArray = nestedArray.filter((v, i) => i > 0);

    return getObjectNestedValue(nextValue, remainingNestedArray.join('.'));
  }

  return actualValue[nestedArray[0]];
}

const replaceFormatter = (object, formatter) => {
  var regExp = /\[([^\]]+)\]/g;
  var matches = formatter.match(regExp);

  matches.forEach((match) => {
    let search = match.replace('[', '').replace(']', '');
    let value = getObjectNestedValue(object, search);

    formatter = formatter.replace(match, value);
  });

  return formatter;
};

export const getSimpleSelectorAsyncData = async (
  url,
  defaultVal = null,
  valueKey = "id",
  labelFormatter = "[id] - [nome]"
) => {
  const response = await sendRequest(url);
  let dados = [];
  if (defaultVal !== null) {
    dados.push(defaultVal);
  }
  response.forEach((r) => {
    dados.push({
      value: replaceFormatter(r, `[${valueKey}]`),
      label: replaceFormatter(r, labelFormatter),
    });
  });
  return dados;
};

export const isNumeric = (s) => {
  return !isNaN(s - parseFloat(s));
}

export const dateFormat = (date, format = 'd/m/Y H:i', initialFormat = 'Y-m-d H:i:s') => {
  let object = new DateTime(date, initialFormat);
  return object.format(format);
}
export const now = (format = 'd/m/Y H:i') => {
  let object = new DateTime();
  return object.format(format);
}

export const toCammelCase = (phrase, firstWordUppercased = false) => {
  const words = phrase.split(/[-_\s]/g);

  let func = firstWordUppercased ? "toUpperCase" : "toLowerCase";
  let cammelCase = "";
  for (let word of words) {
    cammelCase += word[0][func]() + word.slice(1).toLowerCase();
    func = "toUpperCase";
  }

  return cammelCase;
}

export const showToast = (type, title, message, html = <></>) => {
  toast[type](
    <AvisosModal
      tipo={type}
      titulo={title}
      mensagem={
        message
      }
    >
      {html}
    </AvisosModal>,
    {
      transition: Slide,
      hideProgressBar: true,
      autoClose: 3000,
    }
  );
}

export const randomBetween = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const enviroment = process.env.REACT_APP_ENVIROMENT;
export const base_url =
  enviroment === "sandbox" || enviroment === "development"
    ? process.env.REACT_APP_BACKEND_URL_SANDBOX
    : process.env.REACT_APP_BACKEND_URL;
