import React, { Fragment } from "react";
import axios from "axios";
import { toast, Slide } from "react-toastify";
import { sendRequest } from "../models/general_model";
import AvisosModal from "../views/custom/modal/AvisosModal";
import { AlertTriangle } from "react-feather";

export default function useRequest() {
  var addType = true;
  var responseType = null;
  var localHeaders = null;

  const setAddType = (type) => {
    addType = type;
  };

  const setResponseType = (type) => {
    responseType = type;
  };

  const setHeaders = (headers) => {
    localHeaders = headers;
  };

  const handleResponseMessage = (response) => {
    if (typeof response.type === "undefined") return true;
    if (!response.type || response.type === "") return true;

    var returnType = response.type === 'error' ? false : true;

    if (typeof response.data !== "undefined") return showErrorFromData(response, returnType);

    if (typeof response.title === "undefined") return returnType;
    if (!response.title || response.title === "") return returnType;

    if (typeof response.message === "undefined") return returnType;
    if (!response.message || response.message === "") return returnType;

    toast[response.type](
      <AvisosModal
        tipo={response.type}
        titulo={response.title}
        mensagem={response.message}
      />,
      {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 4000,
      }
    );

    return returnType;
  };

  const showErrorFromData = (response, returnType) => {

    toast[response.type](
      <AvisosModal
        tipo={response.type}
        titulo={(
          <>
            <AlertTriangle size={15} /> {response.title}
          </>)}
        mensagem={(
          <ul>
            {Object.keys(response.data).map((key, idx) => (
              <Fragment key={`error_${idx}`}>
                {Array.isArray(response.data[key]) ? (
                  <>
                    {response.data[key].map((err, idx2) => (
                      <li key={`error_${idx}_${idx2}`}>
                        {err}
                      </li>
                    ))}
                  </>
                ) : (
                  <li>
                    {response.data[key]}
                  </li>
                )}
              </Fragment>
            ))}
          </ul>
        )}
      />,
      {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 4000,
      }
    );

    return returnType;
  };



  const handleResponse = (response) => {
    if (!response) return null;

    const isOk = handleResponseMessage(response);

    if (!isOk) return null;

    if (typeof response.payload === "undefined") return response;

    return response.payload;
  };

  const request = async (method, url, data = null, options = {}) => {
    const response = await sendRequest(
      url,
      method,
      data,
      options?.localHeaders ?? localHeaders,
      options?.addType ?? addType,
      options?.responseType ?? responseType
    );

    return handleResponse(response);
  }

  const post = async (url, data = null, options = {}) => {
    const response = await request("POST", url, data, options);
    return response;
  };

  const get = async (url, options = {}) => {
    const response = await request("GET", url, null, options);
    return response;
  };

  const del = async (url, data, options = {}) => {
    const response = await request("DELETE", url, data, options);
    return response;
  };

  return { setAddType, setResponseType, setHeaders, post, get, del };
}
