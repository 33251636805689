import React from "react";
import { AlertTriangle, CheckCircle } from "react-feather";
import Avatar from "@components/avatar";

const AvisosModal = ({tipo, titulo, mensagem, children}) => (
  <>
    <div className="toastify-header">
      <div className="title-wrapper">
        <Avatar size="sm" color={tipo === 'erro' || tipo === 'error' ? 'danger' : 'success'} icon={tipo === 'erro' || tipo === 'error' ? <AlertTriangle size={15} /> : <CheckCircle size={15} />} />
        <h6 className="toast-title font-weight-bold">{titulo}</h6>
      </div>
    </div>
    <div className={`toastify-body $text-white`}>
      <span style={{whiteSpace: "pre-wrap"}}>{mensagem}</span>
      {children}
    </div>
  </>
);
export default AvisosModal;
